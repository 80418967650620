import { getSiteLocale, getViewMode } from './index';
import { ExperimentsBag } from '@wix/wix-experiments';
import { getCurrentSiteUser } from './current-site-user';
import { getBiToken } from '../getBiToken';
import { getVideosCount } from '../getVideosCount';

import DEVICE_TYPES from '../../constants/device-types';
import {
  isGraphAPIEnabled,
  isV3ToV2MappingAPIEnabled,
} from '@wix/wix-vod-shared/common';
import { getWidgetData, WidgetData } from './getWidgetData';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { FedopsInteractionsNames } from '../../constants/fedops-interaction-names';

interface GetHydratedSourceOptions {
  experiments: ExperimentsBag;
  isModal: boolean;
  translations: Record<string, string>;
}
export type HydratedSource = Awaited<ReturnType<typeof getHydratedSource>>;

export async function getHydratedSource(
  {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    config,
    essentials: { httpClient },
  }: ControllerParams['controllerConfig'],
  flowAPI: ControllerParams['flowAPI'],
  { experiments, isModal, translations }: GetHydratedSourceOptions,
) {
  const viewMode = getViewMode(wixCodeApi);
  const siteOwnerId = platformAPIs.bi?.ownerId;
  const { instanceId, instance } = appParams;
  const __SITE_URL__ = wixCodeApi.location.baseUrl;
  const __CURRENT_SITE_USER__ = await getCurrentSiteUser(
    wixCodeApi.user.currentUser,
  );
  const styleParams = config.style.styleParams;
  const siteLocale = getSiteLocale(wixCodeApi);
  const fullSiteUrl = wixCodeApi.location.url; // full url
  const deviceType = wixCodeApi.window.formFactor.toLowerCase();
  const biToken = getBiToken(instance, platformAPIs);
  const metaSiteId = platformAPIs.bi?.metaSiteId;
  const templateMetaSiteId = (platformAPIs.bi as any)?.templateMetaSiteId;

  let widgetData: WidgetData = {} as WidgetData; // we need to understand what to do when we couldn't fetch it, why we return an empty object

  if (!isModal) {
    flowAPI.fedops.interactionStarted(FedopsInteractionsNames.GET_WIDGET_DATA);
    const { baseUrl } = wixCodeApi.location;

    const videosCount = getVideosCount({
      isMobile: deviceType === DEVICE_TYPES.MOBILE,
      styleParams,
    });

    try {
      // TODO: styleParams.fonts typings are wrong, the channelId and videoId are coming as string values
      const fonts = (styleParams.fonts || {}) as unknown as Record<
        string,
        string
      >;
      const { channelId, videoId } = fonts;
      widgetData = await getWidgetData({
        queryParams: {
          channelId,
          videoId,
          instance,
          videosCount,
          metaSiteId,
          locale: siteLocale,
          apiVersion: isGraphAPIEnabled()
            ? 'v3'
            : isV3ToV2MappingAPIEnabled()
            ? 'v3v2mapping'
            : 'v2',
        },
        translations,
        httpClient,
        baseUrl,
        viewMode: wixCodeApi.window.viewMode,
      });
      flowAPI.fedops.interactionEnded(FedopsInteractionsNames.GET_WIDGET_DATA);
    } catch (e: any) {
      flowAPI.reportError(new Error('Error getting widget data', { cause: e }));
    }
  }

  return {
    siteOwnerId,
    instanceId,
    instance,
    viewMode,
    appSettings: styleParams,
    compId,
    metaSiteId,
    templateMetaSiteId,
    widgetData,
    __SITE_URL__,
    __CURRENT_SITE_USER__,
    __EXPERIMENTS__: experiments,
    siteLocale,
    fullSiteUrl,
    deviceType,
    biToken,
  };
}
